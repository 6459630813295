<template>
  <section>
    <ApolloQuery
      :query="require('@/graphql/InvoicesShared.gql')"
      fetchPolicy="cache-and-network"
    >
      <!-- Loading -->

      <template slot-scope="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>

        <div v-else-if="data">
          <v-card-title> Invoices </v-card-title>
          <v-simple-table
            class="order-expanded-table elevation-1"
            style="margin: 20px 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th>Date Range</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoiceItem, orderItemIdx) in data.invoices
                    .slice()
                    .reverse()
                    .filter((item) => item.shared == true)"
                  :key="orderItemIdx"
                >
                  <td>
                    <span
                      luxon:date_med="{
                        value: invoiceItem.dateCreated + 'T12:00:00',
                      }"
                    />
                  </td>
                  <td>
                    <span
                      v-luxon:date_med="{
                        value: invoiceItem.dateStart + 'T12:00:00',
                      }"
                    />
                    -
                    <span
                      v-luxon:date_med="{
                        value: invoiceItem.dateEnd + 'T12:00:00',
                      }"
                    />
                  </td>
                  <td>${{ invoiceItem.cost }}</td>
                  <td :style="{ color: invoiceItem.paid ? 'green' : 'red' }">
                    {{
                      (invoiceItem.paid ? invoiceItem.paid : invoiceItem.paid)
                        ? "yes"
                        : "no"
                    }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="downloadInvoice(null, invoiceItem)"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-download</v-icon
                        >
                      </template>
                      <span>Download Invoice</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <!-- No result -->
        <div v-else class="no-result apollo">No results</div>
      </template>
    </ApolloQuery>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    // EnergyChart: () => import("@/components/charts/EnergyChart"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["user", "strapi"]),
  },
  async mounted() {},
  methods: {
    async downloadInvoice(item, invoiceItem) {
      var invoiceOwner = invoiceItem.customer
        ? invoiceItem.customer.name
        : `${invoiceItem.user.firstName} ${invoiceItem.user.lastName}`;

      let pdfName = `${invoiceOwner}. Invoice #${invoiceItem.id}.`;
      var imgData = require("@/assets/base64/logo.js");
      // console.log(imgData);
      var doc = new jsPDF();
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`Created: ${invoiceItem.dateCreated}`, 10, 10);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(14);
      doc.text(`${invoiceOwner}.`, 10, 20);
      doc.text(`Invoice #${invoiceItem.id}`, 10, 28);
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`(${invoiceItem.dateStart} - ${invoiceItem.dateEnd})`, 10, 35);
      doc.setTextColor(0, 0, 0);
      doc.addImage(imgData.default, "JPEG", 160, 5, 40, 19.6); //0.49

      doc.autoTable({
        // styles: { fillColor: [255, 255, 255] },
        // tableLineWidth: 1,
        // columnStyles: { 1: { halign: "center" }, 2: { halign: "center" }  }, //, fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 40 },
        head: [
          ["Date", "Appointment time", "Destination", "Passenger", "Cost"],
        ],
        body: invoiceItem.records.map((item) => {
          return [
            item.date,
            item.time,
            item.destination,
            item.patientName,
            `$${item.price}`,
          ];
        }),
      });

      doc.setFontSize(11);
      doc.text(
        `Total: $${invoiceItem.cost}`,
        168,
        50 + 10 * invoiceItem.records.length
      );

      doc.setTextColor(160, 160, 160);
      doc.setFontSize(11);
      doc.text(
        invoiceItem.customer
          ? `THANK YOU FOR ALLOWING US TO PARTICIPATE IN YOUR PATIENTS CARE`
          : "THANK YOU",
        30,
        50 + 10 * (invoiceItem.records.length + 5)
      );
      doc.setTextColor(0, 0, 0);

      //pages
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width; //Optional
      const pageHeight = doc.internal.pageSize.height; //Optional
      doc.setFontSize(10); //Optional
      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 5; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
          align: "center", //Optional text styling});
        });
      }

      doc.save(pdfName + ".pdf");
    },
  },
};
</script>
<style lang="scss" scoped>
.stats-rows {
  max-width: 1000px;
  margin: 0 auto;
}

.stats-card {
  background: transparent;
}

.stats-card__inner {
  // background: #4d5f6d;
  height: 150px;
  width: 250px;
}

.stats-card__inner-title {
  color: #3e688a;
  font-size: 32px;
  text-align: center;
  padding-top: 10px;
}

.stats-card__inner-value {
  color: rgb(217, 55, 49);
  font-size: 40px;
  text-align: center;
  padding-top: 10px;
  font-weight: 500;

  display: flex;
  justify-content: center;
}

.stats-card__inner-second-value {
  color: black;
  padding-top: 10px;
  margin: 0 10px;
  font-size: 25px;
}
</style>
