var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ApolloQuery',{attrs:{"query":require('@/graphql/InvoicesShared.gql'),"fetchPolicy":"cache-and-network"},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading, error, data } }){return [(loading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(data)?_c('div',[_c('v-card-title',[_vm._v(" Invoices ")]),_c('v-simple-table',{staticClass:"order-expanded-table elevation-1",staticStyle:{"margin":"20px 0"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Date Created")]),_c('th',[_vm._v("Date Range")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Paid")]),_c('th',[_vm._v("Actions")])])]),_c('tbody',_vm._l((data.invoices
                  .slice()
                  .reverse()
                  .filter((item) => item.shared == true)),function(invoiceItem,orderItemIdx){return _c('tr',{key:orderItemIdx},[_c('td',[_c('span',{attrs:{"luxon:date_med":"{\n                      value: invoiceItem.dateCreated + 'T12:00:00',\n                    }"}})]),_c('td',[_c('span',{directives:[{name:"luxon",rawName:"v-luxon:date_med",value:({
                      value: invoiceItem.dateStart + 'T12:00:00',
                    }),expression:"{\n                      value: invoiceItem.dateStart + 'T12:00:00',\n                    }",arg:"date_med"}]}),_vm._v(" - "),_c('span',{directives:[{name:"luxon",rawName:"v-luxon:date_med",value:({
                      value: invoiceItem.dateEnd + 'T12:00:00',
                    }),expression:"{\n                      value: invoiceItem.dateEnd + 'T12:00:00',\n                    }",arg:"date_med"}]})]),_c('td',[_vm._v("$"+_vm._s(invoiceItem.cost))]),_c('td',{style:({ color: invoiceItem.paid ? 'green' : 'red' })},[_vm._v(" "+_vm._s((invoiceItem.paid ? invoiceItem.paid : invoiceItem.paid) ? "yes" : "no")+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.downloadInvoice(null, invoiceItem)}}},'v-icon',attrs,false),on),[_vm._v("mdi-download")])]}}],null,true)},[_c('span',[_vm._v("Download Invoice")])])],1)])}),0)]},proxy:true}],null,true)})],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No results")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }